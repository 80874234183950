@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  position: relative;
  height: 100%;
}

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hoverable.outlined {
  border-radius: 4px;
  border: 1px solid #dddddd;
}

.hoverable {
  cursor: pointer;
  user-select: none;
}

.hoverable:hover {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

/* contenteditable */
[contenteditable="true"]:focus {
  outline: none;
  /* font-weight: 300; */
}

[contenteditable="true"]:empty:before {
  content: attr(data-placeholder);
  display: block;
  color: #aaa;
  pointer-events: none;
}

.grape-root [contenteditable="true"]:empty:focus {
  background: rgba(52, 39, 72, 0.1);
}

/* draggable */
.draggable-grape {
  outline: none;
}

.draggable-grape .grape-list-item {
  transform: scale(1.01);
  transition: all 300ms;

  position: relative;
  background-color: white;
}

.draggable-grape.dragging .grape-list-item::before {
  content: "";
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: 0;
  border: 1px solid #eee;
  border-bottom: 0;
}

.draggable-grape.combining .grape-list-item {
  transform: scale(0.8);
}

.draggable-grape.combine-for .grape-list-item {
  box-shadow: 0 0 10px inset rgba(100, 120, 255, 0.5);
  transition-delay: 300ms;
}

.add-btn-hoverable {
  transition: all 300ms;
  /* borderTop: "1px solid #eee", */
  font: inherit;
  color: #333;
  padding: 8px;
  font-size: 15px;
  margin: 3px;
  margin-top: 8px;
  border-radius: 3px;
}

.add-btn-hoverable:hover {
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}
.add-btn-hoverable:hover::before {
  color: black;
}

.add-btn-hoverable:focus {
  /* box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.12); */
  background-color: rgba(100, 120, 255, 0.07);
  box-shadow: rgb(100 120 255 / 50%) 0px 0px 0px 1px, rgb(100 120 255 / 50%) 0px 2px 4px;
}

.add-btn-hoverable:not(:focus):not(:empty) {
  background-color: rgba(215, 15, 15, 0.07);
  box-shadow: rgb(215 15 15 / 50%) 0px 0px 0px 1px, rgb(215 15 15 / 50%) 0px 2px 4px;
}

/* small scrollbar */
.sm-sb::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.sm-sb::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.sm-sb::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

/* Handle on hover */
.sm-sb::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* no scrollbar */
.no-sb {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.no-sb::-webkit-scrollbar {
  display: none;
}
.droppable-container {
  height: 100%;
  height: 0;
}
.droppable-container > div {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.droppable-container > div::-webkit-scrollbar {
  display: none;
}

/* others */
.btn-anchor:hover {
  cursor: pointer;
  color: #8764b8;
  text-decoration: underline;
}
